import React, { useEffect, useState } from 'react';
import agents from '../../static/agentsInfo.json'
import LoadingDots from '../Loading/LoadingDots';


const Agent = ({ agentName }) => {


    const [agent, setAgent] = useState({})

    useEffect(() => {
        setAgent(agents["agents"][agentName]);
    }, [agentName]);


    const [imageSource, setImageSource] = useState(`./agent_thumbnails/${agentName}.png`)

    if (!agent) {
        return (
            <>
            </>
        )
    }

    return (

        <div className='agent-card'>
            <div className='agent-card-header'>
                <img src={imageSource} className='agent-thumbnail'></img>
                <div className='agent-name'>{agentName}</div>
                <div className='agent-role'>{agent.role}</div>
            </div>
            <div className='agent-card-content'>
                <div className='agent-card-content-title'>Skills</div>
                <div className='agent-skills-tasks'>{agent.skills}</div>
                <div className='agent-card-content-title'>Tasks</div>
                <div className='agent-skills-tasks'>{agent.tasks}</div>
            </div>
            <div className='agent-card-footer'>
                {agent.model}
            </div>

        </div>
    );
};

export default Agent;