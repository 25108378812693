import React, { useState } from 'react';
import pdf from "../../static/pdf.png"

function Modal({setIsModalOpen, isModalOpen, leftPannelContent, setLeftPannelContent, setIsLoading}) {

    const [isImageClicked, setImageClicked] = useState([false, false, false]);
    const [isPDFClicked, setPDFClicked] = useState(false)

    const handleImageClick = (event) => {
        const id = parseInt(event.target.id, 10);
        setImageClicked((isImageClicked) =>
            isImageClicked.map((clicked, index) =>
            index === id ? !clicked:clicked)
        ); // Toggle the clicked state
    };

    const handlePDFClick = () => {
        setPDFClicked(!isPDFClicked)
    }


    const closeModal = () => {
        setLeftPannelContent((leftPannelContent) => [
            leftPannelContent[0],true, leftPannelContent[2]

        ]
        
    )
        setIsModalOpen(false);
        

        //setIsLoading(true)
      };

      const closeModalCancel = () => {
        
        setIsModalOpen(false);
        

        //setIsLoading(true)
      };

      const changeStyle = () => {

        document.getElementById('modal-briefs').classList.toggle('modal-briefs-selected')
        

      }


    return (
        <div className='modal'>
            <h3>Images</h3>
            <div className='modal-images'>
                <img src='./product_images/input-image.png' className={isImageClicked[0] ? 'clicked' : ''} id="0" onClick={handleImageClick}></img>
                <img src='./product_images/input-image-2.png' className={isImageClicked[1] ? 'clicked' : ''} id="1" onClick={handleImageClick}></img>
                <img src='./product_images/input-image-3.png' className={isImageClicked[2] ? 'clicked' : ''} id="2" onClick={handleImageClick}></img>

            </div>
            <h3>Briefs</h3>

            <div className='modal-briefs' id="modal-briefs" onClick={changeStyle}>
                <img src={pdf} className={isPDFClicked ? 'clicked' : ''} onClick={handlePDFClick}></img>
                <p className='brief-title'>ElectricTropix_Brief.pdf</p>
            </div>
            <div className='button row'>
                <button className='accept-modal-button' onClick={closeModal}>Upload</button>
            </div>
            <div className='button row'>
                <button className='cancel-modal-button' onClick={closeModalCancel}>Cancel</button>
            </div>
        </div>
    );
}

export default Modal;