import React, { useEffect, useState } from "react";
import './App.css';
import AgentsChat from './Components/AgentsChat/AgentsChat';
import Chat from './Components/Chat/Chat';
import LeftPannel from './Components/LeftPannel/LeftPannel';
import Summary from "./Components/Summary/Summary";


function App() {

  const [leftPannelContent, setLeftPannelContent] = useState(["No info provided yet", false, false])


  return (
    <>
      <div className='main-container'>
        <LeftPannel leftPannelContent={leftPannelContent} />
        <Chat leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} />
      </div>
      

    </>

  );
}

export default App;
