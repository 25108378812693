import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import MyDocument from "./ReportPDF";
import ReportPDF from "./ReportPDF";

function Summary() {

    const [documentPath, setDocumentPath] = useState("")
    const [waitOver, setWaitOver] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setWaitOver(true)
            
        
        }, 2000)
    },[])

    useEffect(() => {
        if(document.getElementById("summary-card")){
        document.getElementById("summary-card").scrollIntoView({block: "start"})
    }

    }, [waitOver])



    const downloadReport = () => {
        setDocumentPath("./generated_documents/report.pdf")
    }

    const downloadTranscript = () => {
        setDocumentPath("./generated_documents/transcript.pdf")

    }

    return (
        <>
            <div className="summary-container" id="summary-container">
                <div className="manager-message">
                    <div className="manager-message-content">
                        <p>Done! Here is a summary of the team’s work.</p>
                    </div>
                </div>

                {waitOver && (

                <div className="summary-card" id = "summary-card">
                    <div className="summary-card-header">Conclusion</div>
                    <div className="summary-card-content">
                        <p>
                            The European energy drinks market is projected to grow and experiencing a notable shift towards natural and organic products. This aligns perfectly with ElectricTropix's value proposition.
                            Our pricing strategy at $4.99 places ElectricTropix competitively within the premium segment, its unique packaging and sensory experiences will help the product stand out in a market dominated by established players such as Monster, PepsiCo, and Red Bull.
                        </p>
                        <p>
                            The packaging will combine eco-friendliness and visual appeal. The use of tempered glass ensures durability and a high-end feel, and the biodegradable, UV-reactive label adds a unique aesthetic touch.
                        </p>

                        <p>
                            Focusing on high-traffic locations near universities, gyms, nightlife areas and leveraging strategic partnerships with specialty stores will significantly enhance ElectricTropix's market penetration.
                            Employing tactical in-store promotions such as sampling stations, interactive displays, and limited-time offers will maximize brand visibility, customer engagement and sales.
                        </p>

                        <p>
                            ElectricTropix’s strategic event sponsorships and immersive social media campaigns, which include interactive booths, DJ collaborations, and influencer partnerships, will amplify brand visibility and create memorable experiences likely to be shared widely.
                            Additionally, customizable labels enhance user engagement and the product's shareability, appealing particularly to young adults
                        </p>

                        <p>
                            By implementing comprehensive taste tests and gathering detailed user feedback, ElectricTropix will effectively fine-tune the balance between tropical flavors and mint, experiment with natural sweeteners, and perfect the texture through varied carbonation levels.
                        </p>
                        <br/>


                        <div className="summary-card-image-row">
                            <img src="./product_images/round_1-image-1.jpg"></img>
                            <img src="./product_images/round_2-image-2.png"></img>                          
                            <img src="./product_images/round_1-image-2.png"></img>
                            <img src="./product_images/round_3-image-1.jpg"></img>
                        </div>
                    </div>
                    <div className="summary-buttons-container">
                        <button onClick={downloadReport}>Download report</button>
                        <button onClick={downloadTranscript}>Download full transcript</button>

                    </div>

                    {documentPath && <ReportPDF documentPath={documentPath} setDocumentPath={setDocumentPath} />}
                </div>
                )}
            </div>


        </>
    )
}

export default Summary;