import React from 'react';
import LeftPannelAgent from './LeftPanelAgent';


const LeftPannelAgents = () => {


    return (
        <div className='left-pannel-team-container'>
            <LeftPannelAgent agentName = "Mark" />
            <LeftPannelAgent agentName = "Bev" />
            <LeftPannelAgent agentName = "DJ" />
            <LeftPannelAgent agentName = "Sally" />
            <LeftPannelAgent agentName = "Susy" />
            <LeftPannelAgent agentName = "Ado" />
            <LeftPannelAgent agentName = "Igen" />

        </div>
    );
};

export default LeftPannelAgents;