import React, { useEffect, useState } from "react";

function MessageList({messages}) {
      return (
        <ul className="message-list">                 
          {this.messages.map(message => {
            return (
             <li key={message.content}>
               <div>
                 {message.role}
               </div>
             </li>
           )
         })}
       </ul>
      )
  }

export default MessageList;