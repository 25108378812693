import React, { useEffect, useState } from 'react';
import agents from '../../static/agentsInfo.json'


const LeftPannelAgent = ({agentName}) => {

    const [agent, setAgent] = useState({})

    useEffect(() => {
        setAgent(agents["agents"][agentName]);
    }, [agentName]);


    const [imageSource, setImageSource] = useState(`./agent_thumbnails/${agentName}.png`)

    return (
            <div className='left-pannel-team-row'>
                <img src={imageSource} />
                <div className='left-pannel-agent-name'>{agentName} - {agent.role}</div>
            </div>

    );
};

export default LeftPannelAgent;