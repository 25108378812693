import React from "react";
import LoadingDots from "../Loading/LoadingDots";
import LeftPannelAgents from "../LeftPannel/LeftPannelAgents";
import LeftPannelFiles from "./LeftPannelFiles";

function LeftPannel({leftPannelContent}) {
    return(
    <>
    
    <div className="left-pannel" id="left-pannel" >
        <div className="left-pannel-block" id ="left-pannel-block-1" >
            <h5 className="left-pannel-title">Project</h5>
            <p className="left-pannel-content">{leftPannelContent[0]}</p>
            
        </div>
        <div className="left-pannel-block" id ="left-pannel-block-1" >
            <h5 className="left-pannel-title">Files</h5>
            <p className="left-pannel-content">
                {leftPannelContent[1] ? <LeftPannelFiles/> : "No files uploaded yet" }
                </p>
        </div>
        <div className="left-pannel-block" id ="left-pannel-block-1" >
            <h5 className="left-pannel-title">Team</h5>
            <p className="left-pannel-content">
                {leftPannelContent[2] ? <LeftPannelAgents/> : "No agents assigned yet"}</p>
        </div>
    </div>
    </>
    )
}

export default LeftPannel;